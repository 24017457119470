<template>
    <div class="canvas Step">
        <h1
        class="Headline is-size-5"
        v-t="'pages.profile.institutionTitle'"
        />
        <Form ref="institutionForm" v-slot="slotProps" >
            <input-component :fieldName="fieldNames.name" :fieldLabel="$t('form.institution.name')" rules="" :read-only="true" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.street" :fieldLabel="$t('form.street')" rules="" :read-only="true" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.postalCode" :fieldLabel="$t('form.postalCode')" rules="" :read-only="true" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.city" :fieldLabel="$t('form.city')" rules="" :read-only="true" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.country" :fieldLabel="$t('form.country')" rules="" :read-only="true" type="text" :errors="slotProps"/>
        </Form>
    </div>
</template>

<script>
import InputComponent from './partials/InputComponent.vue'
import { Form } from 'vee-validate'

export default {
    name: 'InstitutionData',

    components: {
        InputComponent,
        Form
    },
    props: {
        institution: Object
    },

    data () {
        return {
            fieldNames: {
                name: 'name',
                street: 'street',
                postalCode: 'postalCode',
                city: 'city',
                country: 'country'
            }
        }
    },

    mounted () {
        this.setFieldValues()
    },

    methods: {
        setFieldValues () {
            if (this.institution && this.$refs.institutionForm) {
                        this.$refs.institutionForm.setFieldValue(this.fieldNames.name, this.institution.name)
                        this.$refs.institutionForm.setFieldValue(this.fieldNames.street, this.institution.address.street)
                        this.$refs.institutionForm.setFieldValue(this.fieldNames.postalCode, this.institution.address.postalCode)
                        this.$refs.institutionForm.setFieldValue(this.fieldNames.city, this.institution.address.city)
                        this.$refs.institutionForm.setFieldValue(this.fieldNames.country, this.institution.address.countryCode)
            }
        }
    },

    watch: {
        institution () {
            this.setFieldValues()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';

.canvas {
    display: flex;
    flex-direction: column;
    margin: 20px 5px 0 5px;

    @include media('>=md') {
    margin: 30px 30px;

    }
}
.canvas::v-deep .Headline {
    margin-top: 0;
}
</style>
