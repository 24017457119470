<template>
<div class="canvas Step">
    <h1
      class="Headline is-size-4"
      id="nameheading"
    >
      {{ fullNameWithTitle }}
    </h1>

        <Form
        v-slot="slotProps"
        ref="form"
        @submit="submitProfile"
        >
          <div class="alert alert-success" v-if="hasBeenSaved">
            {{$t('saveSuccessMessage')}}
          </div>

          <div class="alert alert-danger" v-if="hasBackendError">
            {{$t('BannerSelectRole.backendError')}}
          </div>

          <h1
            class="Headline is-size-5"
            v-t="'pages.profileEdit.personalHeading'"
          />
          <input-component :fieldName="fieldNames.email" :fieldLabel="$t('form.email')" rules="" :read-only="!this.isEdit" @click.native="openChangeEmailDialog" type="text" :errors="slotProps" :disabled="true"/>
          <input-component :fieldName="fieldNames.title" :fieldLabel="$t('form.title')" :rules="`max:20`" type="text" :errors="slotProps" :read-only="!this.isEdit"/>
          <input-component :fieldName="fieldNames.firstName" :fieldLabel="$t('form.firstName')" :rules="`required|max:20`" type="text" :errors="slotProps" :read-only="!this.isEdit"/>
          <input-component :fieldName="fieldNames.lastName" :fieldLabel="$t('form.lastName')" :rules="`required|max:20`" type="text" :errors="slotProps" :read-only="!this.isEdit"/>

          <div>
            <InputDatepickerComponent
              :fieldName="fieldNames.dateofBirth"
              :fieldLabel="$t('form.dateOfBirth')"
              :validationRules="'max130|min16'"
              :isDisabled="!this.isEdit"
            />
          </div>

          <input-select-component
            :fieldName="fieldNames.gender"
            :fieldLabel="$t('form.gender')"
            :options="genderOptions"
            :isDisabled="!this.isEdit"
            :rules="``"
          />
          <input-component :fieldName="fieldNames.phone" :fieldLabel="$t('form.phone')" :rules="`max:25`" type="text" :errors="slotProps" :read-only="!this.isEdit"/>

          <h1
            id="addressheading"
            class="Headline is-size-5"
            v-t="'pages.profileEdit.address'"
          />
          <input-component :fieldName="fieldNames.street" :fieldLabel="$t('form.street')" rules="max:100" type="text" :errors="slotProps" :read-only="!this.isEdit"/>
          <input-component :fieldName="fieldNames.postalCode" :fieldLabel="$t('form.postalCode')" rules="max:20" type="text" :errors="slotProps" :read-only="!this.isEdit"/>
          <input-component :fieldName="fieldNames.city" :fieldLabel="$t('form.city')" rules="max:50" type="text" :errors="slotProps" :read-only="!this.isEdit"/>
          <input-component :fieldName="fieldNames.stateOrProvince" :fieldLabel="$t('form.stateOrProvince')" rules="max:50" type="text" :errors="slotProps" :read-only="!this.isEdit"/>

          <input-select-component
            :fieldName="fieldNames.country"
            :fieldLabel="$t('form.country')"
            :options="countries"
            :isDisabled="!this.isEdit"
            :rules="``"
          />

        <simple-spinner v-if="isLoading" :show="true" class-names="is-red is-big" />
        <div v-if="isEdit" class="edit-buttons">
          <button
            class="Button form-button"
            :class="{ 'is-grey': failed || isLoading || !slotProps.meta.valid }"
            :disabled="failed || !slotProps.meta.valid"
            v-text="$t('save')"
          />
          <button
            class="ButtonLink form-button is-grey"
            :disabled="isLoading"
            v-text="$t('cancelButtonLabel')"
            @click="cancelEditProfile"
          />
        </div>
    </Form>
    <button v-if="!isEdit" @click="isEdit=!isEdit" @submit.prevent class="Button padded">{{ $t('form.edit') }}</button>
    <button v-if="allowPasswordChange" @click="$refs.changePasswordModal.open()" class="ButtonDecoratedLink">{{ $t('form.ChangePassword') }}</button>
    <change-password-dialog ref="changePasswordModal" :userEmail="this.user.email" />
    <change-email-dialog ref="changeEmailDialog" />

  </div>
</template>

<script>
import { Form } from 'vee-validate'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'
import { getOptionsAsObjectFromTranslation } from '../translations'
import { appSettings } from '@/settings'
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue'
import ChangeEmailDialog from '@/components/ChangeEmailDialog.vue'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputDatepickerComponent from '@/components/partials/InputDatepickerComponent.vue'

import * as utils from '../utils.js'

export default {
  name: 'PortalProfileEditForm',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.profileEdit.meta.title')
    }
  },

  components: {
    Form,
    SimpleSpinner,
    ChangePasswordDialog,
    ChangeEmailDialog,
    InputSelectComponent,
    InputComponent,
    InputDatepickerComponent
  },

  async mounted () {
    await this.loadUserProfile()
    this.isEdit = false
    this.reset()
  },

  data () {
    return {
      failed: false,
      errors: {},
      isLoading: false,
      hasBeenSaved: false,
      hasBackendError: false,
      fieldNames: {
        email: 'email',
        title: 'title',
        firstName: 'firstName',
        lastName: 'lastName',
        dateofBirth: 'dateofBirth',
        gender: 'gender',
        phone: 'phone',
        street: 'street',
        postalCode: 'postalCode',
        city: 'city',
        stateOrProvince: 'stateOrProvince',
        country: 'country'
      }
    }
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'userCountry',
      'isProfessional',
      'isCareGiver',
      'isRecipient',
      'isBasic',
      'isRejected',
      'fullName',
      'title',
      'canChangeUserType',
      'isMedelEmployee'
    ]),

    ...mapGetters('profile', {
      editable: 'editable',
      userType: 'type'
    }),

    // since this computed is accessing a variable in the profile store and we need it to communicate
    // to other components using the same store, we have to explicitly set the setter - usually computed
    // properties are read only. See: https://vuejs.org/v2/guide/computed.html
    isEdit: {
      get () {
        return this.editable
      },
      set (value) {
        this.$store.commit('profile/setEditable', value)
      }
    },

    fullNameWithTitle () {
      return this.fullName + (this.title ? `, ${this.title}` : '')
    },

    genderOptions () {
      return getOptionsAsObjectFromTranslation('dropdowns.genderOptions')
    },

    countries () {
      const entries = this.$tm('dropdowns.countries')
      // restricted for signup form
      return utils.sortListByLabel(Object.keys(entries).filter(key => {
        return appSettings.registrationCountries.indexOf(key) >= 0
      }).map(key => ({
        code: key,
        label: entries[key]
      })))
    },

    isCountryUs () {
      return this.userCountry === 'us'
    },

    isSaveDisabled () {
      return this.isLoading
    },

    userTypeKey () {
      if (!this.user.userType) return 'recipient'

      return this.user.userType.toLowerCase()
    },

    allowPasswordChange () {
      return !this.isEdit && !this.isMedelEmployee
    }
  },

  methods: {
    ...mapActions({
      saveUserProfileImage: 'user/updateUserProfileWithFormData',
      loadUserProfile: 'user/getUserFromDB'
    }),
    ...mapMutations({ updateUser: 'user/updateUser' }),

    openChangeEmailDialog (e) {
      e.preventDefault()
      if (!this.isEdit || this.isMedelEmployee) {
        return
      }
      this.$refs.changeEmailDialog.open()
    },

    cancelEditProfile (e) {
      this.reset()
      this.isEdit = false
      e.preventDefault()
    },

    reset () {
      this.hasBackendError = false
      this.hasBeenSaved = false

      this.$refs.form.setFieldValue(this.fieldNames.email, this.user.email)
      this.$refs.form.setFieldValue(this.fieldNames.title, this.user.title)
      this.$refs.form.setFieldValue(this.fieldNames.firstName, this.user.firstName)
      this.$refs.form.setFieldValue(this.fieldNames.lastName, this.user.lastName)
      this.$refs.form.setFieldValue(this.fieldNames.gender, this.genderOptions.find(i => i.code === this.user.gender))
      this.$refs.form.setFieldValue(this.fieldNames.dateofBirth, this.user.dateOfBirth)
      this.$refs.form.setFieldValue(this.fieldNames.phone, this.user.phone)
      this.user.address = this.user.address ? this.user.address : {}
      this.$refs.form.setFieldValue(this.fieldNames.street, this.user.address.street)
      this.$refs.form.setFieldValue(this.fieldNames.postalCode, this.user.address.postalCode)
      this.$refs.form.setFieldValue(this.fieldNames.city, this.user.address.city)
      this.$refs.form.setFieldValue(this.fieldNames.stateOrProvince, this.user.address.stateOrProvince)
      this.$refs.form.setFieldValue(this.fieldNames.country, this.countries.find(i => i.code === this.user.address.countryCode))
      this.$store.commit('profile/resetType')

      this.language = this.$i18n.locale ? this.$i18n.locale : 'en'
    },

    async submitProfile (values) {
      this.isLoading = true
      this.hasBackendError = false
      this.hasBeenSaved = false
      try {
        // explicitly map fields, we do not want to send the whole form, since it might contain more data than we want.
        let countryToSend = values[this.fieldNames.country]?.code
        let dataToSend = {
          gender: values[this.fieldNames.gender] ? values[this.fieldNames.gender].code : null,
          title: values[this.fieldNames.title]?.trim(),
          firstName: values[this.fieldNames.firstName]?.trim(),
          lastName: values[this.fieldNames.lastName]?.trim(),
          dateOfBirth: values[this.fieldNames.dateofBirth],
          phone: values[this.fieldNames.phone]?.trim(),
          address: {
            street: values[this.fieldNames.street]?.trim(),
            city: values[this.fieldNames.city]?.trim(),
            postalCode: values[this.fieldNames.postalCode]?.trim(),
            stateOrProvince: values[this.fieldNames.stateOrProvince]?.trim(),
            countryCode: countryToSend
          }
        }

        if (this.canChangeUserType) {
          dataToSend.userType = this.userType ? this.userType.code : null
        }

        if (this.isBasic || this.isRejected) {
          dataToSend.country = countryToSend
        }
        await this.$store.dispatch('user/updateUserProfile', dataToSend)
        this.hasBeenSaved = true
        this.isEdit = false
        this.$root.$emit('user-data-changed')
        this.$emit('saved')
        this.loadUserProfile()
        this.reset()
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        this.hasBackendError = true
      }

      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';
@import '../styles/_colors.scss';

.Step .Headline {
  margin-top: 2em;
}

.Button {
  border: 2px solid $color-red-500;
  background-color: $white;
  color: $color-red-500;
  margin-top: 1em;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.canvas {
  margin: 40px 5px;
  @include media('>=md') {
    margin: 90px 30px;
  }
}

#nameheading {
  margin-top: 0;
}

.padded {
  margin-top: 2em;
}

.edit-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

// scoped styles usually do not apply to child components, but in this case we want to style
// a child component in a very specific way on this form only. See:
// https://vue-loader.vuejs.org/guide/scoped-css.html#child-component-root-elements
.profile-edit-form::v-deep .form-input-title {
  background-color: $white;
  z-index: 1;

  @include media ('<=sm') {
    padding: 0 0.4em;
    position: absolute;
    top: -0.7em;
    left: 0.4em;
  }
}
.profile-edit-form::v-deep  .form-input-label .v-select .vs__dropdown-toggle {
  @include media ('<=sm') {
    padding-left: 0.4em;
  }
}
.profile-edit-form::v-deep  .form-input-label .v-select .vs__selected  {
  @include media ('<=sm') {
    padding-left: 0em;
  }
}

.profile-edit-form::v-deep  .form-input-label .v-select .vs__selected-options  {
  @include media ('<=sm') {
    padding: 0em;
  }
}
</style>
