<template>
        <div class="xz">
        <div class="profile-image-container">
            <file-picker
                v-if="selectFileStep"
                id="filePicker"
                class="profile-image"
                @vfp-file-added="fileAdded"
                @vfp-invalid-file-type="showInvalidFileTypeError"
                accept="image/*"
            />

            <canvas
                id="imageResizeCanvas"
                ref="imageResizeCanvas"
                style="display:none"
                width="256"
                height="256"
            />

            <img
                ref="tempImage"
                v-show="confirmImageStep"
                class="profile-image"
                src="@/assets/images/profile-image-placeholder.png"
                :alt="$t('pages.profileEdit.uploadedImageAltText')"
            />

            <img
                ref="profileImage"
                class="profile-image"
                v-show="unchangedImageStep"
                :src="profileImageOrPlaceHolder"
                :alt="$t('pages.profileEdit.profileImageAltText')"
            />

            <div class="alert alert-success" v-if="hasImageBeenSaved">
                {{$t('saveSuccessMessage')}}
            </div>

            <div class="alert alert-danger" v-if="hasImageUploadError">
                {{ imageUploadErrorMessage }}
            </div>

            <div
                class="twobuttons"
                v-if="confirmImageStep || selectFileStep"
            >
                <button
                    class="ButtonLink"
                    :class="{ 'is-grey': isLoading || !hasValidImage }"
                    :disabled="isLoading || !hasValidImage"
                    v-text="$t('save')"
                    @click="sendImage"
                />

                <button
                    class="ButtonLink is-grey"
                    v-text="$t('cancelButtonLabel')"
                    @click="resetImageChangeProcess"
                />
            </div>

            <button
                v-if="unchangedImageStep"
                class="ButtonLink"
                @click="startImageChangeProcess"
            >{{ $t('changeButtonLabel')}}
            </button>
        </div>
    </div>
</template>

<script>

import FilePicker from '@/components/partials/FilePicker.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'ProfileImage',

    components: {
        FilePicker
    },

    data () {
        return {
            imageUploadErrorMessage: '',
            profileImageState: 'initial',
            hasImageBeenSaved: false,
            hasImageUploadError: false,
            file: '',
            failed: false,
            isLoading: false
        }
    },

    mounted () {
        this.getProfileImageData()
    },

    computed: {
        ...mapGetters('user', [
        'user',
        'profileImageUrl'
        ]),

        profileImageOrPlaceHolder () {
            return this.profileImageUrl ? this.profileImageUrl : this.placeholderImageUri
        },

        placeholderImageUri () {
            return require('@/assets/images/profile-image-placeholder.png')
        },

        unchangedImageStep () {
            return this.profileImageState === 'initial'
        },

        selectFileStep () {
            return this.profileImageState === 'change'
        },

        confirmImageStep () {
            return this.profileImageState === 'confirm'
        },

        isImageTooBig () {
            return this.hasImage && this.file.size > this.maxFileSize
        },

        hasImage () {
            return !!this.file
        },

        hasValidImage () {
            return this.hasImage && !this.isImageTooBig
        },

        maxFileSize () {
            // hardcoding this to 5MB
            return 5 * 1048576
        }

    },

    methods: {
        ...mapActions({
            saveUserProfileImage: 'user/updateUserProfileWithFormData',
            getProfileImageData: 'user/getProfileImageData'
         }),
        ...mapMutations({
            updateUser: 'user/updateUser',
            resetProfileImage: 'user/resetProfileImage'
        }),

        showInvalidFileTypeError () {
            this.imageUploadErrorMessage = this.$t('pages.profileEdit.invalidFileTypeErrorMessage')
            this.hasImageUploadError = true
            setTimeout(this.resetTempFile, 5000)
        },

        startImageChangeProcess () {
            this.profileImageState = 'change'
        },

        resetImageChangeProcess () {
            this.profileImageState = 'initial'
        },

        calculateOffSetWidthAndHeight (img, canvas) {
            var width, height, offsetWidth, offsetHeight

            if (img.height >= img.width) {
                width = canvas.width * img.width / img.height
                height = canvas.height
            } else {
                width = canvas.width
                height = canvas.height * img.height / img.width
            }
            offsetWidth = (canvas.width - width) > 0 ? (canvas.width - width) / 2 : 0
            offsetHeight = (canvas.height - height) > 0 ? (canvas.height - height) / 2 : 0

            return {
                width: width,
                height: height,
                offsetWidth: offsetWidth,
                offsetHeight: offsetHeight
            }
        },

        fileAdded (files) {
            var file = files[0]
            this.file = file

            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = evt => {
                let img = new Image()
                img.onload = () => {
                    const canvas = this.$refs.imageResizeCanvas
                    var ctx = canvas.getContext('2d')
                    ctx.clearRect(0, 0, this.$refs.imageResizeCanvas.width, this.$refs.imageResizeCanvas.height)
                    const calculatedImageProperties = this.calculateOffSetWidthAndHeight(img, canvas)
                    ctx.drawImage(img, calculatedImageProperties.offsetWidth, calculatedImageProperties.offsetHeight, calculatedImageProperties.width, calculatedImageProperties.height)
                    this.$refs.tempImage.src = this.$refs.imageResizeCanvas.toDataURL('image/png')
                }
                img.src = evt.target.result
            }

            if (this.isImageTooBig) {
                this.imageUploadErrorMessage = this.$t('pages.profileEdit.imageTooBigMessage')
                this.hasImageUploadError = true
                setTimeout(this.resetTempFile, 5000)
                return
            }

            this.profileImageState = 'confirm'
        },

        async sendImage () {
            this.isLoading = true
            var formData = new FormData()
            formData.append('profilePicture', this.file)
            try {
                await this.saveUserProfileImage(formData)
                this.resetProfileImage()
                await this.getProfileImageData()
                this.hasImageBeenSaved = true
                setTimeout(this.removeImageSavedMessage, 5000)
                this.profileImageState = 'initial'
            } catch (err) {
                this.resetTempFile()
                this.imageUploadErrorMessage = this.$t('pages.profileEdit.imageUploadError')
                this.hasImageUploadError = true
                setTimeout(this.removeImageUploadError, 5000)
                this.$emit('reset')
            }
            this.isLoading = false
        },

        resetTempFile () {
            this.file = null
            this.$refs.tempImage.src = this.placeholderImageUri
            this.removeImageUploadError()
        },

        removeImageSavedMessage () {
            this.hasImageBeenSaved = false
        },

        removeImageUploadError () {
            this.hasImageUploadError = false
        }
    }
}
</script>

<style lang="scss">
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';

.profile-image-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;

    margin: 90px 15px 0px 15px;
    padding: 0;
    max-width: 200px;

  @include media('<=md') {
      margin: 45px 15px 0px 15px;
  }
}

#filePicker {
    min-height: 150px;
    min-width: 150px;
    max-width: 200px;
    max-height: 200px;
    width: 20vw;
    height: 20vw;

    border-radius: 100%;
}

.ButtonLink {
    &.is-grey {
        color: $color-grey-300;
    }
}

.twobuttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-image {
    margin-bottom: 10px;

    min-height: 150px;
    min-width: 150px;
    max-width: 200px;
    max-height: 200px;
    width: 20vw;
    height: 20vw;

    border-radius: 100%;
    box-shadow: 0 18px 18px rgba(0, 0, 0, 0.2);
}

.xz {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 30 15;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

</style>
