<template>
  <article class="ThreeColumns">
    <section class="left-container">
        <slot name="contentLeft" />
    </section>

    <section class="middle-container">
        <slot name="contentMiddle" />
    </section>

    <section class="right-container">
      <slot name="contentRight"/>
    </section>

  </article>
</template>

<script>
export default {
  name: 'ThreeColumns'
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports.scss';

.ThreeColumns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $color-grey-100;

  @include media('>=md') {
    flex-direction: row;
  }

  .left-container {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;

    order: 1;

    @include media('>=md') {
      text-align: center;
      width: 25%;
      order: 1;
    }
  }

  .middle-container {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    background-color: white;

    order: 3;

    @include media('>=md') {
      width: 50%;
      order: 2;
    }
  }

  .right-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;

    order: 2;

    @include media('>=md') {
      justify-content: flex-start;
      width: 25%;
      order: 3
    }
  }

}
</style>
