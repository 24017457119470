<template>
  <article class="PortalProfile">
    <profile-body />
    <profile-footer />
  </article>
</template>
<script>
import ProfileBody from '@/components/ProfileBody.vue'
import ProfileFooter from '@/components/ProfileFooter.vue'

export default {
  name: 'PortalProfile',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.profile.meta.title')
    }
  },

  components: {
    ProfileBody,
    ProfileFooter
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports';

.PortalProfile {
  margin: 92px 0px 0px;
  min-height: calc(100vh - 210px);
  min-width: 320px;
  overflow: auto;
  background-color: white;

  @include media('>=sm') {
    margin: 120px 15px 15px;
  }

  @include media('>=md') {
    margin: 134px 15px 15px;
  }
}
</style>
