<template>
  <article
    class="BannerUpgrade ff-signa"
    :class="[{ 'is-dark': isDark }, classNames]"
  >
    <p
    v-html="translateWithMymedelString('upgradeForMoreServices')"
    />

    <link-router
      class-names="Button is-smaller"
      path="profile-edit"
    >
      {{ $t('links.upgradeProfile.label') }}
    </link-router>
  </article>
</template>

<script>
import localeMessages from '@/mixins/localeMessages'

export default {
  name: 'BannerUpgrade',
  mixins: [ localeMessages ],

  props: {
    isDark: {
      type: Boolean,
      default: false
    },

    classNames: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.BannerUpgrade {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1.5em 2em;
  padding-right: 1.5em;
  border: 1px solid $color-grey-300;
  background-color: $white;
  font-size: 1.5em;
  border-radius: 15px;
  background-color: #c60c30;
  color: $white;

  .Button {
    color: $white;
    text-align: left;
    border: 1px solid $white;
    margin: 0 1em;

    @include media('>=md') {
      text-align: center;
    }
  }

  @include media('<=md') {
    padding : 1em 1em;
  }
}
.BannerUpgrade::v-deep p{
  margin-top: 0;
  margin-bottom: 0;
}
</style>
