<template>
  <sweet-modal ref="changeEmailModal" overlay-theme="dark" hide-close-button>
    <h1 class="Headline is-size-4" v-t="'changeEmail.title'" />

    <p v-if="!showError && !showSuccess">{{ $t('changeEmail.infoMessage') }}</p>
    <div v-if="showSuccess" class="alert alert-success password-reset-message">{{ $t('changeEmail.successMessage') }}</div>

    <Form @submit="submit" v-slot="errors" class="form-input">
      <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email|disallowEmail:${user.email}`" type="email" :errors="errors"/>
      <input-component :fieldName="fieldNames.emailConf" :fieldLabel="emailConfirmationFieldName" :rules="`required|confirm:@${fieldNames.email}`" type="email" :errors="errors"/>

      <div v-if="showError" class="alert alert-danger password-reset-message">{{$t('changeEmail.errorMessage')}}</div>

      <div class="content sub-headline">
        <div class="row">
          <button v-if="!showSuccess" class="Button" :disabled="isLoading"
            v-text="$t('changeEmail.sendChangeEmailButtonText')" />
        </div>

        <div class="row">
          <button class="Button" @click="close" v-text="this.closeButtonText" />
        </div>
      </div>
    </Form>
  </sweet-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import InputComponent from '@/components/partials/InputComponent.vue'

export default {
  name: 'ChangeEmailDialog',

  components: {
    Form,
    InputComponent
  },

  data () {
    return {
      showSuccess: false,
      showError: false,
      isLoading: false,
      fieldNames: {
        email: 'email',
        emailConf: 'emailConf'
      }
    }
  },

  computed: {
    ...mapGetters('user', ['user']),
    emailFieldLabel () {
      return this.$t('form.email')
    },
    emailConfirmationFieldName () {
      return this.$t('form.emailConfirmation')
    },
    closeButtonText () {
      return this.showSuccess ? this.$t('changeEmail.closeButtonAndLogoutText') : this.$t('changeEmail.closeButtonText')
    }
  },

  methods: {
    ...mapActions({ initiateEmailChange: 'user/initiateEmailChange' }),

    open () {
      this.showSuccess = false
      this.showError = false

      this.$refs.changeEmailModal.open()
    },
    close (e) {
      e.preventDefault()
      this.$refs.changeEmailModal.close()
      if (this.showSuccess) {
        this.$auth0.logout()
      }
    },
    async submit (values) {
      this.isLoading = true
      try {
        await this.initiateEmailChange(values[this.fieldNames.email])
        this.renderResultMessage(true)
      } catch (error) {
        this.renderResultMessage(false)
      }
      this.isLoading = false
    },
    renderResultMessage (isSuccess) {
      this.showSuccess = isSuccess
      this.showError = !isSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.row {
  margin-top: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.password-reset-message {
  margin-top: 1em;
}

.is-grey {
  background-color: #d1d4d3;
}
</style>
