<template>
    <div class="top-container">
        <div class="institution-image-container">
            <img
                ref="institutionImage"
                class="institution-image"
                :src="institutionImageUrl"
                :alt="$t('pages.profileEdit.institutionImageAltText')"
            />
        </div>
    </div>
</template>
<script>

export default {
    name: 'InstitutionImage',

    props: {
        url: {
            type: String
        }
    },

    computed: {
        institutionImageUrl () {
            return this.url
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';

.institution-image-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;

    margin: 10px 15px 0px 15px;
    padding: 0;
    max-width: 200px;

  @include media('<=md') {
      margin: 10px 15px 0px 15px;
  }
}

.institution-image {
    min-height: 150px;
    min-width: 150px;
    max-width: 200px;
    max-height: 200px;
}

.top-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 30px 15px;
}
</style>
