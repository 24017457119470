<template>
  <Field
    :name="fieldName"
    :label="fieldLabel"
    :rules="validationRules"
    v-slot="{ errors, field }"
    v-model="selected">
    <div :class="Object.assign({'is-error': errors.length > 0}, labelClass)" >
      <small :class="Object.assign({'is-error': errors.length > 0}, titleClass)" v-text="fieldLabel" />
      <v-select
        v-if="!isDisabled"
        v-bind="field"
        :options="options"
        :searchable="searchable"
        :label="optionLabel"
        class="form-input-field"
        :class="Object.assign({'is-error': errors.length > 0})"
        :disabled="isDisabled"
        @input="$emit('input', $event)"
      />
      <div v-else class="inactive-form-value">{{field?.value?.label ?? ""}}</div>

      <p class="form-input-error" v-show="errors.length" v-text="errors[0]"/>
    </div>
  </Field>
</template>
<script>

import formFields from '@/mixins/formFields'
import { Field } from 'vee-validate'
export default {
  name: 'InputSelect',

  mixins: [ formFields ],

  components: { Field },

  data () {
    return {
      selected: ''
    }
  },

  props: {
    value: {
      type: [String, Object]
    },

    fieldLabel: {
      type: String,
      default: 'Input'
    },

    fieldName: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    optionLabel: {
      type: String,
      default: 'label'
    },

    validationRules: {
      type: String,
      default: 'required'
    },

    searchable: {
      type: Boolean,
      default: true
    },

    options: {
      type: Array,
      required: true
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    narrowColumn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    labelClass () {
      return {
        'form-input-label': !this.isDisabled,
        'inactive-form-input-label': this.isDisabled,
        'container-inputselect': true,
        'narrow': this.narrowColumn
      }
    },

    titleClass () {
      return {
        'd-block': !this.isDisabled,
        'form-input-title': !this.isDisabled,
        'inactive-form-input-title': this.isDisabled,
        'item': true
      }
    },
    valueOrPlaceholder () {
      return this.value ? this.value.label : this.placeholder
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/_component-imports.scss';
@import '../../styles/_steps.scss';

.container-inputselect {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;

  &.narrow {
    @include media('>=md') {
      flex-direction: column;
    }
  }
}

.column {
  flex-direction: column
}

.form-input-label {
    position: relative;
    display: block;
    &.is-error {
        color: $color-red-500;
        border-color: $color-red-500;
    }
}

.form-input-title {
    color: $color-grey-500;
    margin-bottom: 5px;
    font-size: 0.8em;

    &.is-error {
      border-color: $color-red-500;
      color: $color-red-500;
    }
}

.form-input-field {
    background-color: $white;
    font-size: 1.3em;
    line-height: 1.5em;
    padding: 0.5em 1em;
    width: 100%;
    border: 1px solid $color-grey-300;
    border-radius: 4px;

    @include media('<=sm') {
      margin-top: 0.2em;
      padding: 0.4em 0.3em;
    }

    &.is-error {
      border-color: $color-red-500;
      color: $color-red-500;
    }
}

.form-input-error {
    color: $color-red-500;
    font-size: 12px;
    margin: 4px 0 0;
  }
</style>

<style lang="scss">
  @import '../../styles/_colors.scss';

  .form-input-field {
    &.v-select {
      padding: 0;

      &.is-error {
        border-color: $color-red-500;
        color: $color-red-500;
      }

      .vs__dropdown-toggle {
        border: 4px;
        padding: 9px 12px;
      }

      .vs__selected {
        border: 0;
        margin: 0;
      }

      .vs__dropdown-menu {
        border-radius: 0;
        box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
      }

      .vs__dropdown-option--selected {
        font-weight: bold;
      }

      .vs__dropdown-option--highlight {
        background: $color-grey-100;
        color: $black;
      }
    }
  }
</style>
