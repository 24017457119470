<template>
  <section class="ProfileBody">
    <three-columns>
      <template v-slot:contentLeft>
        <profile-image />
      </template>
      <template v-slot:contentMiddle>
        <portal-profile-edit-form/>
        <banner-upgrade v-if="userCanUpgrade" class-names="n-m-lr" />
      </template>
      <template v-slot:contentRight>
        <profile-meta />
      </template>
    </three-columns>
    <three-columns v-show="isInstitutionVerified">
      <template v-slot:contentLeft>
        <institution-image v-if="institutionImageUrl" :url="institutionImageUrl" />
      </template>
      <template v-slot:contentMiddle>
        <div class="Step">
          <institution-data :institution="institution"/>
        </div>
      </template>
    </three-columns>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { appSettings } from '@/settings'
import BannerUpgrade from '@/components/BannerUpgrade.vue'
import ThreeColumns from '@/layouts/ThreeColumns.vue'
import PortalProfileEditForm from './PortalProfileEditForm.vue'
import ProfileImage from './ProfileImage.vue'
import ProfileMeta from './ProfileMeta.vue'
import InstitutionData from '@/components/InstitutionData.vue'
import InstitutionImage from '@/components/InstitutionImage.vue'

export default {
    name: 'ProfileBody',

    components: {
      BannerUpgrade,
      ThreeColumns,
      PortalProfileEditForm,
      ProfileImage,
      ProfileMeta,
      InstitutionData,
      InstitutionImage
    },

    data () {
      return {
        isInstitutionVerified: false
      }
    },

    computed: {
      ...mapGetters('user', [
      'user',
      'isBasic',
      'isRejected',
      'isCareGiver',
      'isRecipient',
      'isProfessional',
      'userType',
      'institution',
      'institutionImageUrl'
    ]),
    userCanUpgrade () {
      if (!(this.isBasic || this.isRejected)) {
        return false
      }
      if (!this.userType) {
        return false
      }
      if (this.isProfessional && this.countryHasProfessionalRegistration) {
        return true
      }
      if ((this.isRecipient || this.isCareGiver) && this.countryHasFullRegistration) {
        return true
      }
      return false
    },
    countryHasProfessionalRegistration () {
      return appSettings.registrationCountriesProfessionals.includes(this.user.country)
    },
    countryHasFullRegistration () {
      return appSettings.fullRegistrationCountries.includes(this.user.country)
    }
  },
  methods: {
   ...mapActions({
      getInstitution: 'user/getInstitutionData',
      loadInstitutionImageData: 'user/loadInstitutionImageData'
    })
  },
  watch: {
    user: {
      async handler () {
        try {
          if (this.user?.professionalData?.institutionId) {
            await this.getInstitution()
            this.isInstitutionVerified = this.institution?.verificationStatus === 'verified'
            await this.loadInstitutionImageData()
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error('Unable to load institution information', error)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.ProfileBody {
  background-color: $white;
  display: block;
  width: 100%;
}
</style>
