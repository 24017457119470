<template>
  <section class="ProfileFooter">
    <div>
      {{ $t('pages.profile.issues') }} <a
        :href="'mailto:myMED-ELsupport@medel.com'"
        class="Link"
        v-t="'links.contactUs.label'"
      />
    </div>
    <div v-if="!isMedelEmployee()">
      <button class="ButtonLink" @click="openDialog">{{ $t('pages.profile.delete') }}</button>
      <sweet-modal icon="warning" ref="confirmDelete">
        <h1 class="delete-account-header">{{ $t('pages.profile.confirmDeletionHeader')}}</h1>
        <div class="modal-message">{{ $t('pages.profile.confirmDeletionText')}}</div>
        <div class="modal-body">
          <button
            @click="closeDeleteModal"
            :disabled="isLoading"
            class="Button padded">{{$t('cancelButtonLabel')}}
          </button>
          <button
            @click="deleteProfile"
            :disabled="isLoading"
            class="ButtonDecoratedLink">{{$t('pages.profile.delete')}}
          </button>
        </div>
      </sweet-modal>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { logout } from '../auth/auth0'

export default {
  name: 'ProfileFooter',
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions(
      { sendDeleteProfile: 'user/deleteProfile' }
    ),
    ...mapGetters(
      { isMedelEmployee: 'user/isMedelEmployee' }
    ),
    openDialog () {
      this.$refs.confirmDelete.open()
    },
    async deleteProfile () {
      this.isLoading = true
      try {
        await this.sendDeleteProfile()
        logout()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        // TODO: talk to devs and Zsofia for how to handle this
      }
      this.isLoading = false
    },
    closeDeleteModal () {
      this.$refs.confirmDelete.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.ProfileFooter {
  background-color: $white;
  padding: 15px 15px;
  margin-top: 15px;
  border-top: 1px solid $color-grey-300;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    margin: 0;
  }
}

.delete-account-header{
  color: $color-red-500;
}

.modal-message{
  margin-bottom: 1em;
}

.modal-body{
  display: flex;
  justify-content: space-evenly;
}
</style>
