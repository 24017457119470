<template>
    <div class="Step canvas profile-edit-form">
        <input-select
            :isEdit="canChangeUserType && isEdit"
            placeholder="N/A"
            v-model="userType"
            :searchable="false"
            :options="userTypes"
            :fieldName="fieldNames.userRole"
            :fieldLabel="$t('form.userRole')"
            :narrowColumn="true"
        />
        <div class="form-input" v-if="displayStatus">
            <label class="inactive-form-input-label">
                <small class="inactive-form-input-title">{{ $t(`accountStates.label`) }}</small>
                <div class="inactive-form-value">
                    {{ accountStatusTranslation }}
                    <i v-if="verified" class="icon icon-verified color-green"/>
                    <i v-if="pending" class="icon icon-pending color-red"/>
                </div>
            </label>
        </div>
        <div class="form-input" v-else>
            <label class="inactive-form-input-label">
                <small class="inactive-form-input-title">{{ $t(`accountTypes.label`) }}</small>
                <div class="inactive-form-value">
                    {{ accountTypeTranslation }}
                </div>
            </label>
        </div>
    </div>
</template>

<script>

import { accountStates } from '@/settings'
import { mapGetters } from 'vuex'
import InputSelect from './partials/InputSelect.vue'
import { getOptionsAsObjectFromTranslation } from '@/translations'

export default {
    name: 'ProfileMeta',

    components: {
        InputSelect
    },

    data () {
        return {
            userTypes: getOptionsAsObjectFromTranslation('form.userRoles'),
            fieldNames: {
                userRole: 'userRole'
            }
        }
    },

    computed: {
        ...mapGetters('user', [
            'user',
            'canChangeUserType',
            'accountType',
            'accountStatus'
        ]),

        ...mapGetters('profile', {
            isEdit: 'editable',
            type: 'type'
        }),

        userType: {
            get () {
                return this.type
            },
            set (value) {
                this.$store.commit('profile/setType', value)
            }
        },

        accountTypeTranslation () {
            return this.$t(`accountTypes.${this.accountType}`)
        },

        accountStatusTranslation () {
            return this.$t(`accountStates.${this.accountStatus}`)
        },

        displayStatus () {
            return this.accountStatus !== accountStates.NONE
        },

        pending () {
            return this.accountStatus === accountStates.PENDING
        },

        verified () {
            return this.accountStatus === accountStates.VERIFIED
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import '../styles/_steps.scss';

.canvas {
    display: flex;
    flex-direction: column;
    margin: 20px 5px 0 5px;

    @include media('>=md') {
        margin-top: 50px;
        padding-left: 30px;
        padding: 90px 15px;
        padding-left: 50px;
    }
}

// scoped styles usually do not apply to child components, but in this case we want to style
// a child component in a very specific way on this form only. See:
// https://vue-loader.vuejs.org/guide/scoped-css.html#child-component-root-elements
.profile-edit-form::v-deep .form-input-title {
  background-color: $white;
  z-index: 1;

  @include media ('<=sm') {
    padding: 0 0.4em;
    position: absolute;
    top: -0.6em;
    left: 0.4em;
  }
}
.profile-edit-form::v-deep  .form-input-label .v-select .vs__dropdown-toggle {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  @include media ('<=sm') {
    padding-left: 0.4em;
  }
}
.profile-edit-form::v-deep  .form-input-label .v-select .vs__selected  {
    overflow: hidden;
    flex: 0 1 auto;
  @include media ('<=sm') {
    padding-left: 0em;
  }
}
.profile-edit-form::v-deep  .form-input-label .v-select .vs__actions  {
    flex: 0 0 auto;
}

.profile-edit-form::v-deep  .form-input-label .v-select .vs__selected-options  {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  @include media ('<=sm') {
    padding: 0em;
  }
}

.profile-edit-form::v-deep  .inactive-form-input-label {
    display: flex;
    flex-wrap: nowrap;

    border-bottom: none;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media ('<=sm') {
        padding: 0em;
    }

    @include media('>=md') {
      flex-direction: row;
      justify-content: flex-start;
      text-align: start;
    }
}

.icon::before {
    border: unset;
}
.color-red {
    color: #c60c30;
}
.color-green {
    color: lightgreen;
}
</style>
