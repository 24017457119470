<template>
<div>
  <Field
    :name="fieldName"
    :label="fieldLabel"
    tag="div"
    :rules="validationRules"
    class="form-input"
    v-slot="{ errors }"
  >
    <div :class="labelClass" >
      <small :class="titleClass" v-text="label" />
      <v-select
        v-if="isEdit"
        v-model="selected"
        :options="options"
        :searchable="searchable"
        :label="optionLabel"
        class="form-input-field d-block"
        :disabled="isDisabled"
      />
      <div v-else class="inactive-form-value">{{ valueOrPlaceholder }}</div>

      <p class="form-input-error" v-show="errors.length" v-text="errors[0]"/>
    </div>
  </Field>
</div>
</template>
<script>

import formFields from '@/mixins/formFields'
import { Field } from 'vee-validate'
export default {
  name: 'InputSelect',

  mixins: [ formFields ],

  components: { Field },

  data () {
    return {
      selected: this.value
    }
  },

  props: {
    value: {
      type: [String, Object]
    },

    fieldName: {
      type: String,
      required: true
    },

    fieldLabel: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    optionLabel: {
      type: String,
      default: 'label'
    },

    validationRules: {
      type: String,
      default: 'required'
    },

    searchable: {
      type: Boolean,
      default: true
    },

    options: {
      type: Array,
      required: true
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    isEdit: {
      type: Boolean,
      default: true
    },

    narrowColumn: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selected (newValue, oldValue) {
      this.$emit('input', newValue)
    },

    value (newValue, oldValue) {
      this.selected = newValue
    }
  },

  computed: {
    labelClass () {
      return {
        'form-input-label': this.isEdit,
        'inactive-form-input-label': !this.isEdit,
        'container-inputselect': true,
        'narrow': this.narrowColumn
      }
    },

    titleClass () {
      return {
        'd-block': this.isEdit,
        'form-input-title': this.isEdit,
        'inactive-form-input-title': !this.isEdit,
        'item': true
      }
    },
    valueOrPlaceholder () {
      return this.value ? this.value.label : this.placeholder
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/_component-imports.scss';
@import '../../styles/_steps.scss';

.container-inputselect {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;

  &.narrow {
    @include media('>=md') {
      flex-direction: column;
    }
  }
}

.column {
  flex-direction: column
}
</style>
