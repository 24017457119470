<template>
  <sweet-modal ref="changePasswordModal" overlay-theme="dark">
    <div class="termsOfUseContent">
      <h1 class="Headline is-size-4" v-t="'changePassword.title'" />
      <p v-if="!showPasswordResetError && !showPasswordResetSuccess">
        {{ $t('changePassword.infoMessage', { email: userEmail }) }}
      </p>
      <div v-if="showPasswordResetSuccess" class="alert alert-success password-reset-message" tag="div">
        {{ $t('changePassword.passwordResetSuccess', { email: userEmail }) }}
      </div>
      <div class="alert alert-danger password-reset-message" v-if="showPasswordResetError">
        {{ $t('changePassword.passwordResetError') }}
      </div>

      <div class="content sub-headline">
        <div class="row">
          <button v-if="!showPasswordResetSuccess" type="button" class="Button" @click="sendChangePasswordEmail">
            {{ $t('changePassword.sendChangePasswordEmailButtonText') }}
          </button>
        </div>
        <div class="row">
          <button type="button" class="Button" @click="close">
            {{ $t('changePassword.closeButtonText') }}
          </button>
        </div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
import { startPasswordReset } from '../auth/auth0'
export default {
  name: 'ChangePasswordDialog',

  props: {
    userEmail: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showPasswordResetSuccess: false,
      showPasswordResetError: false
    }
  },

  methods: {
    open () {
      this.$refs.changePasswordModal.open()
      this.showPasswordResetSuccess = false
      this.showPasswordResetError = false
    },
    close () {
      this.$refs.changePasswordModal.close()
    },
    async sendChangePasswordEmail () {
        let localScope = this
        startPasswordReset(this.userEmail, function (err, resp) {
        if (err) {
          // eslint-disable-next-line
          console.log(err.message)
          localScope.renderPasswordResetResultMessage(false)
        } else {
          // eslint-disable-next-line
          console.log(resp)
          localScope.renderPasswordResetResultMessage(true)
        }
      })
    },
    renderPasswordResetResultMessage (isSuccess) {
      this.showPasswordResetSuccess = isSuccess
      this.showPasswordResetError = !isSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.linkButton, .linkButton:hover{
  background:none;
  color:#979594;
}

.row {
  margin-top: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.password-reset-message {
  margin-top: 1em;
}
</style>
